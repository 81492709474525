<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-app-bar app :color="$store.state.appThemeColor" dark dense>
        <v-btn icon @click="$router.back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>Daftar Mata Pelajaran Kamu</v-toolbar-title>
      </v-app-bar>
    </template>
    <v-container>
      <img-cover :src="require('@/assets/img/books.jpg')"></img-cover>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">mdi-book</v-icon> {{ subjects.length }} Mata
        Pelajaran
      </div>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-bind="attrs"
        v-if="loading"
        type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
      ></v-skeleton-loader>

      <v-list denase height="100%" width="100%" class="mx-0">
        <v-list-item-group color="primary">
          <v-list-item v-for="(subject, i) in subjects" :key="i" two-line>
            <v-list-item-avatar>
              <v-avatar color="blue" size="36">
                <span class="white--text text-h6">{{
                  subject.avatar_text
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="subject.name"></v-list-item-title>
              <v-list-item-subtitle
                v-text="subject.teacher.fullname"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-alert color="warning" v-if="loading == false && subjects.length == 0"
        >Oppss.. Belum ada data.</v-alert
      >
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ImgCover from "@/components/ImgCover.vue";

export default {
  name: "SubjectsList",
  components: { DefaultLayout, ImgCover },

  data: () => ({
    subjects: [],
    loading: null,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
  methods: {
    getList() {
      this.loading = true;
      this.$axios.get("subjects/list").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.subjects = res.data.data;
        } else {
          this.subjects = [];
        }
        this.loading = false;
      });
    },
  },

  mounted() {
    this.getList();
    this.setPageTitle("Mata Pelajaran Kamu", "mdi-book-open");
  },
};
</script>